import { computed, watch } from '@vue/composition-api'
import { useCustomersQuery } from '@/store/customers'

export const useCustomer = ({ customerId }) => {
  const {
    data: customerData,
    refetch,
    isLoading,
    isFetching,
  } = useCustomersQuery.detail({
    customerId,
    options: {
      enabled: !!customerId.value,
    },
  })

  const customer = computed(() => customerData?.value?.customer ?? {})

  //TODO: скорее всего нет необходимости, еще на тесте
  // watch(
  //   () => customerId.value,
  //   async () => {
  //     if (customerId.value) {
  //       await refetch.value()
  //     }
  //   }
  // )

  return {
    customer,
    customerRefetch: refetch,
    isCustomerLoading: isLoading,
    isCustomerFetching: isFetching,
  }
}
