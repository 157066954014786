import { computed, nextTick, watch } from '@vue/composition-api'
import { useType } from '@/models/useType'
import { useTemplate } from '@/models/useTemplate'

const useTypes = ({ document, editor }) => {
  const typeId = computed(() => document.typeId)
  const templateId = computed(() => document.templateId)

  const { type, isAgreementType } = useType({ typeId })
  const { template } = useTemplate({ templateId })

  const typeFields = computed(
    () =>
      type.value.fields?.map(f => ({
        ...f,
        value: document.typeFields.find(_f => _f.key === f.key)?.value || '',
        ids:
          template.value.typeFields
            ?.filter(_f => _f.key === f.key)
            ?.map(_f => _f.id) || [],
      })) || []
  )

  const updateTypeField = async field => {
    await nextTick()
    document.typeFields = typeFields.value.map(f =>
      f.key === field.key
        ? {
            ...f,
            value: field.value,
            name: field.name,
          }
        : f
    )
    editor.value?.$children[0]?.$_instance?.fire('updateField', field)
  }

  const changeTypeHandler = () => {
    if (typeFields.value.length) {
      document.typeFields = typeFields.value.map(f => ({
        value: f.value,
        key: f.key,
        name: f.name,
      }))
    }
  }

  watch(
    () => document.typeId,
    async () => {
      if (!!isAgreementType.value) {
        document.masterId = ''
      }
      changeTypeHandler()
    }
  )

  watch(
    () => type.value,
    () => {
      if (document.documentType !== type.value.type) {
        document.documentType = type.value.type
      }
    }
  )

  return {
    type,
    typeFields,
    updateTypeField,
  }
}

export default useTypes
