<template>
  <Loader v-if="isDocumentLoading || isDocumentFetching" />
  <div v-else>
    <b-card>
      <template #header>
        <div class="d-flex flex-wrap w-100">
          <h4 class="card-title mr-auto">
            {{ $t(`pages.documents.sections.main`) }}
          </h4>
          <feather-icon
            v-if="isEdit && isAgreement && !documentModel.inArchive"
            icon="TruckIcon"
            size="24"
            class="mr-1 text-primary cursor-pointer"
            @click="
              () => {
                $router.push({
                  name: 'journal',
                  query: {
                    masterId: documentModel._id,
                  },
                })
              }
            "
          />
          <feather-icon
            v-if="isEdit"
            icon="PrinterIcon"
            size="24"
            class="mr-1 text-primary cursor-pointer"
            @click="() => printHandler(editor.editorData)"
          />
          <div class="d-flex">
            <p class="mb-0 font-small-2 mr-1">
              {{ $t('forms.labels.inArchive') }}
            </p>
            <FormSwitchGroup
              :value.sync="documentModel.inArchive"
              class="custom-control-primary mb-0"
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </FormSwitchGroup>
          </div>
        </div>
      </template>
      <b-row>
        <b-col md="8" lg="4" xl="3">
          <FormInputGroup
            v-model="documentModel.name"
            :errors="v$.documentModel.name.$errors"
            :disabled="documentModel.inArchive"
            :label="
              type.type === 'consent'
                ? $t('forms.labels.consentName')
                : $t('forms.labels.name')
            "
          />
        </b-col>

        <b-col md="8" lg="4" xl="3">
          <FormSelectTypeGroup
            :key="documentModel.typeId"
            :value.sync="documentModel.typeId"
            :errors="v$.documentModel.typeId.$errors"
            :disabled="documentModel.inArchive || !!typeId"
            :label="$t('forms.labels.type')"
          />
        </b-col>

        <b-col md="8" lg="4" xl="3">
          <FormSelectTemplateGroup
            :key="documentModel.templateId"
            :value.sync="documentModel.templateId"
            :typeId="documentModel.typeId"
            :label="$t('forms.labels.template')"
            :errors="v$.documentModel.templateId.$errors"
            :disabled="documentModel.inArchive || !!templateId"
          />
        </b-col>

        <b-col md="8" lg="4" xl="3">
          <FormSelectCustomerGroup
            :key="documentModel.customerId"
            :value.sync="documentModel.customerId"
            :errors="v$.documentModel.customerId.$errors"
            :customerType="documentModel.customerType"
            :disabled="
              !!documentModel.masterId ||
              documentModel.inArchive ||
              !!masterId ||
              !!customerId
            "
            :label="$t('forms.labels.customer', { name: 1 })"
          />
        </b-col>
        <b-col
          md="8"
          lg="4"
          xl="3"
          v-for="key in Object.keys(additionalCustomers)"
          :key="key"
        >
          <FormSelectCustomerGroup
            :key="documentModel.additionalCustomers[key].customerId"
            :value.sync="documentModel.additionalCustomers[key].customerId"
            :customerType="template.additionalCustomers[key].type"
            :label="
              $t('forms.labels.customer', {
                name: additionalCustomers[key].customerName,
              })
            "
            :disabled="documentModel.inArchive"
            @change="value => updateAdditionalCustomerFields(key, value)"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col md="8" lg="6">
          <FormSelectMasterGroup
            :key="documentModel.masterId"
            :value.sync="documentModel.masterId"
            :customerType="documentModel.customerType"
            :clearable="true"
            :label="$t(`forms.labels.documentMaster`)"
            :disabled="
              isAgreement ||
              documentModel.inArchive ||
              !!masterId ||
              !documentModel.templateId ||
              !documentModel.typeId
            "
          />

          <FormSelectGroup
            :value.sync="documentModel.status"
            :options="[
              { value: 'noСontract', label: $t('forms.options.noСontract') },
              { value: 'inWork', label: $t('forms.options.inWork') },
              { value: 'closed', label: $t('forms.options.closed') },
            ]"
            :disabled="documentModel.inArchive"
            :label="$t('forms.labels.status')"
          />

          <FormSelectGroup
            :value.sync="documentModel.payment"
            :options="[
              { value: 'notPaid', label: $t('forms.options.notPaid') },
              { value: 'partPaid', label: $t('forms.options.partPaid') },
              { value: 'paid', label: $t('forms.options.paid') },
            ]"
            :disabled="documentModel.inArchive"
            :label="$t('forms.labels.payment')"
          />
        </b-col>
        <b-col md="8" lg="6">
          <FormTextareaGroup
            :value.sync="documentModel.comment"
            :disabled="documentModel.inArchive"
            :label="$t('forms.labels.comment')"
          />

          <FormFileGroup
            :value.sync="documentModel.documentScan"
            :label="$t('forms.labels.documentScan')"
            :disabled="documentModel.inArchive"
            accept="*"
            type="document"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-card
      v-if="documentModel.typeId && typeFields.length !== 0"
      :title="$t(`pages.documents.sections.documentFields`)"
    >
      <b-row>
        <b-col v-for="typeField in typeFields" :key="typeField.id">
          <FieldResolver
            :field="typeField"
            :disabled="documentModel.inArchive"
            clsx-input="min-w-200"
            @update="updateTypeField(typeField)"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-card
      v-if="isAgreement && isEdit"
      :title="$t(`pages.documents.sections.documentDependents`)"
    >
      <DependManager
        :documentId="documentModel._id"
        :customerType="documentModel.customerType"
        :disabled="documentModel.inArchive"
      />

      <b-row>
        <b-col
          v-if="!!group.length"
          v-for="(group, key) in groupingDependents"
          :key="key"
          cols="6"
        >
          <SubDocumentTable
            :type="key"
            :name="$t(`pages.types.${key}`)"
            :items="group"
          />
        </b-col>
      </b-row>
    </b-card>

    <Editor
      v-if="isEditorShow"
      :key="template._id + type._id"
      :content-data="template.html"
      :template-fields="templateFields"
      :type-fields="typeFields"
      :master-fields="masterFields"
      :customer-fields="customerFields"
      :additional-customers="additionalCustomers"
      :type="type"
      :editable="false"
      :toolbar="false"
      :disabled="documentModel.inArchive"
      ref="editor"
      @update:field="updateTemplateField"
    />

    <ActionButtons
      :isPrint="isEdit"
      :isJournal="isEdit && isAgreement && !documentModel.inArchive"
      @print="() => printHandler(editor.editorData)"
      @save="saveHandler"
      @saveStay="() => saveHandler(true)"
      @journal="
        () => {
          $router.push({
            name: 'journal',
            query: {
              masterId: documentModel._id,
            },
          })
        }
      "
    />
  </div>
</template>

<script>
import { computed, reactive, ref, onMounted, watch } from '@vue/composition-api'
import { BButton, BCol, BRow, BCard, BTable } from 'bootstrap-vue'
import {
  FormInputGroup,
  FormSelectGroup,
  FormSwitchGroup,
  FormTextareaGroup,
  FormFileGroup,
  FormSelectCustomerGroup,
  FormSelectTemplateGroup,
  FormSelectTypeGroup,
  FormSelectMasterGroup,
} from '@/views/components/forms'
import { useDocumentsQuery } from '@/store/documents'
import { required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import router from '@/router'
import Editor from '@/views/components/editor/Editor_v2.vue'
import useToast from '@/views/components/useToast'
import StatusCell from '@/views/components/table/StatusCell'
import PaymentCell from '@/views/components/table/PaymentCell'
import SubDocumentTable from '@/views/components/pages/SubDocumentTable'
import ActionButtons from '@/views/components/pages/ActionButtons.vue'
import DependManager from '@/views/components/pages/DependManager'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import FieldResolver from '@/views/components/pages/FieldResolver'
import useCustomers from '@/views/pages/documents/useCustomers'
import useTemplates from '@/views/pages/documents/useTemplates'
import useTypes from '@/views/pages/documents/useTypes'
import useMasters from '@/views/pages/documents/useMasters'
import useDependents from '@/views/pages/documents/useDependents'
import useAdditionalCustomers from '@/views/pages/documents/useAdditionalCustomers'
import { printHandler } from '@/utils/helpers'

export default {
  components: {
    Editor,
    BRow,
    BCol,
    BButton,
    BCard,
    BTable,
    FormInputGroup,
    FormSelectGroup,
    FormSwitchGroup,
    FormTextareaGroup,
    FormFileGroup,
    StatusCell,
    PaymentCell,
    SubDocumentTable,
    ActionButtons,
    DependManager,
    FieldResolver,
    FormSelectCustomerGroup,
    FormSelectTemplateGroup,
    FormSelectTypeGroup,
    FormSelectMasterGroup,
  },
  props: {
    documentId: {
      type: String,
      default: '',
    },
  },
  setup(props, { refs }) {
    const { t } = useI18nUtils()
    const toast = useToast()
    const editor = ref(refs.editor)
    const isEdit = computed(() => !!props.documentId)
    const documentId = computed(() => props.documentId || '')
    const { masterId, typeId, templateId, customerId } =
      router?.currentRoute?.query
    const isAgreement = computed(() => type.value.type === 'agreement')
    const isStay = ref(false)

    const toastsText = {
      edit: t('notifications.success.documents.edit'),
      create: t('notifications.success.documents.create'),
    }

    let documentModel = reactive({
      name: '',
      templateId: '',
      typeId: '',
      customerId: '',
      masterId: '',
      templateFields: [],
      typeFields: [],
      customerFields: [],
      customerType: '',
      documentType: '',
      additionalCustomers: {},
      status: 'noСontract',
      payment: 'notPaid',
      comment: '',
      inArchive: false,
      documentScan: '',
      dependents: [],
    })

    // *** USE DOCUMENT *** //

    const { additionalCustomers, updateAdditionalCustomerFields } =
      useAdditionalCustomers({
        document: documentModel,
        editor,
      })

    const { groupingDependents } = useDependents({
      documentId,
    })

    const { customerFields } = useCustomers({
      document: documentModel,
      editor: computed(() => editor.value),
    })

    const { template, templateFields, updateTemplateField } = useTemplates({
      document: documentModel,
    })

    const { type, typeFields, updateTypeField } = useTypes({
      document: documentModel,
      editor: computed(() => editor.value),
    })

    const { master, masterFields } = useMasters({
      document: documentModel,
      editor: computed(() => editor.value),
    })

    const isAdditionalCustomersId = computed(() =>
      Object.values(additionalCustomers.value).every(
        ({ customerId }) => !!customerId
      )
    )

    onMounted(async () => {
      documentModel = Object.assign(documentModel, {
        typeId: typeId ?? '',
        templateId: templateId ?? '',
        customerId: customerId ?? '',
        masterId: masterId ?? '',
      })
    })

    watch(
      () => documentModel.masterId,
      () => {
        if (masterId) {
          documentModel.masterId = masterId
        }
      }
    )

    watch(
      () => documentModel.customerId,
      () => {
        if (customerId) {
          documentModel.customerId = customerId
        }
      }
    )

    // *** DOCUMENT *** //
    const { isLoading: isDocumentLoading, isFetching: isDocumentFetching } =
      useDocumentsQuery.detail({
        documentId,
        options: {
          enabled: computed(() => isEdit.value).value,
          onSuccess: data => {
            if (data.success) {
              documentModel = Object.assign(documentModel, data.document)
            }
          },
          refetchOnMount: 'always',
        },
      })

    const { mutate: onCreate } = useDocumentsQuery.create({
      document: documentModel,
      options: {
        onSuccess(data) {
          if (data.success) {
            toast.success(toastsText.create)
            if (!isStay.value) {
              router.go(-1)
            } else {
              router.push({
                name: 'document_edit',
                params: { documentId: data.document._id },
              })
            }
          } else {
            toast.error(JSON.stringify(data.error))
          }
        },
      },
    })

    const { mutate: onUpdate } = useDocumentsQuery.update({
      documentId,
      document: documentModel,
      options: {
        onSuccess(data) {
          if (data.success) {
            toast.success(toastsText.edit)
            if (!isStay.value) {
              router.go(-1)
              return
            }
            documentModel = Object.assign(documentModel, data.document)
          } else {
            toast.error(JSON.stringify(data.error))
          }
        },
      },
    })

    const saveHandler = isStayHere => {
      v$.value.$touch()
      if (!isValid.value) return
      isStay.value = !!isStayHere
      isEdit.value ? onUpdate() : onCreate()
    }

    const rules = {
      documentModel: {
        name: { required, $autoDirty: true },
        typeId: { required, $autoDirty: true },
        templateId: { required, $autoDirty: true },
        customerId: { required, $autoDirty: true },
      },
    }
    const v$ = useVuelidate(rules, { documentModel })
    const isValid = computed(() => !v$.value.$error)

    const isEditorShow = computed(
      () => !template._id && documentModel.templateId
    )

    return {
      v$,
      editor,
      isEdit,
      isEditorShow,

      documentModel,
      saveHandler,
      printHandler,

      template,
      templateFields,
      updateTemplateField,

      customerFields,

      additionalCustomers,
      updateAdditionalCustomerFields,

      type,
      typeFields,
      updateTypeField,

      master,
      masterFields,

      groupingDependents,

      masterId,
      typeId,
      templateId,
      customerId,

      isAgreement,
      isDocumentLoading,
      isDocumentFetching,
      isAdditionalCustomersId,
    }
  },
}
</script>

<style lang="scss" scoped>
.ck-editor__top {
  display: none;
}
</style>
