import { computed, watch } from '@vue/composition-api'
import { useTemplatesQuery } from '@/store/templates'

export const useTemplate = ({ templateId }) => {
  const { data: templateData, refetch } = useTemplatesQuery.detail({
    templateId,
    options: {
      enabled: !!templateId.value,
    },
  })

  const template = computed(() => templateData.value?.template || {})

  watch(
    () => templateId.value,
    async () => {
      if (templateId.value) {
        await refetch.value()
      }
    }
  )

  return {
    template,
    templateRefetch: refetch,
  }
}
