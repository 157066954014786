<template>
  <div>
    <FormInputGroup
      v-if="field.innerType === 'input'"
      v-model="field.value"
      :label="getLabel(field)"
      :mask="field.mask"
      :class="clsxInput"
      :disabled="disabled"
      @input="update"
    />
    <FormInputGroup
      v-if="field.innerType === 'qr'"
      v-model="field.value"
      :label="getLabel(field)"
      :mask="field.mask"
      :class="clsxInput"
      :disabled="disabled"
      @input="update"
    />

    <FormInputGroup
      v-if="field.innerType === 'email'"
      v-model="field.value"
      :label="getLabel(field)"
      type="email"
      :class="clsxInput"
      :disabled="disabled"
      @input="update"
    />

    <FormSelectGroup
      v-if="field.innerType === 'select'"
      :value.sync="field.value"
      :options="
        field.innerOptions.map(f => ({
          ...f,
          label: f.label ? $t(`forms.options.${f.label}`) : f.name,
        }))
      "
      :label="getLabel(field)"
      :class="clsxInput"
      :disabled="disabled"
      @change="update"
    />

    <FormSelectGroup
      v-if="field.innerType === 'list'"
      :value.sync="field.value"
      :options="
        field.innerOptions.map(f => ({
          ...f,
          label: f.label ? $t(`forms.options.${f.label}`) : f.name,
        }))
      "
      :label="getLabel(field)"
      :class="clsxInput"
      :disabled="disabled"
      :multiple="true"
      @change="update"
    />

    <FormDatepickerGroup
      v-if="field.innerType === 'date'"
      v-model="field.value"
      :label="getLabel(field)"
      :class="['mb-1 mb-md-0', clsxInput]"
      :disabled="disabled"
      @change="update"
    />
  </div>
</template>

<script>
import {
  FormInputGroup,
  FormSelectGroup,
  FormDatepickerGroup,
  FormFileGroup,
} from '@/views/components/forms'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  emits: ['update'],
  components: {
    FormFileGroup,
    FormInputGroup,
    FormSelectGroup,
    FormDatepickerGroup,
  },
  props: {
    field: {
      type: Object,
      default: () => ({
        innerType: 'input',
        value: '',
        name: '',
      }),
    },
    clsxInput: {
      type: String | Object,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    moreLabel: {
      type: String,
      default: () => '',
    },
  },
  setup(props, { emit }) {
    const { t } = useI18nUtils()
    const update = () => {
      emit('update', props.field)
    }

    const getLabel = field =>
      [
        field.label ? t(`forms.labels.${field.label}`) : field.name,
        props.moreLabel,
      ].join('')

    return {
      update,
      getLabel,
    }
  },
}
</script>
