import { computed, watch } from '@vue/composition-api'
import { useDocumentsQuery } from '@/store/documents'

export const useMaster = ({ masterId }) => {
  const { data: masterData, refetch } = useDocumentsQuery.detail({
    documentId: masterId,
    documentType: 'agreement',
    options: {
      enabled: !!masterId.value,
    },
  })

  const master = computed(() => masterData.value?.document || {})

  watch(
    () => masterId.value,
    async () => {
      if (document.masterId) {
        await refetch.value()
      }
    }
  )

  return {
    master,
    masterRefetch: refetch,
  }
}
