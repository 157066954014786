<template>
  <div class="d-flex align-items-end mb-1 gap-05 flex-wrap">
    <FormSelectTypeGroup
      :value.sync="depend.typeId"
      :disabled="disabled"
      :label="$t('forms.labels.type')"
      :size="size"
      :excludes="['agreement']"
      class="min-w-250 mb-0"
    />

    <FormSelectTemplateGroup
      :key="depend.typeId"
      :value.sync="depend.templateId"
      :typeId="depend.typeId"
      :customerType="customerType"
      :label="$t('forms.labels.template')"
      :disabled="disabled || !depend.typeId"
      :size="size"
      class="mb-0 min-w-250 flex-md-grow-1"
    />

    <b-button
      v-ripple
      variant="primary"
      class="mr-1"
      :size="size"
      :disabled="disabled || !depend.templateId || !depend.typeId"
      @click="onCreateDependent"
    >
      {{ $t(`forms.buttons.add`) }}
    </b-button>
  </div>
</template>

<script>
import { ref, toRefs, watch } from '@vue/composition-api'
import { BButton, BCol, BRow } from 'bootstrap-vue'
import {
  FormSelectGroup,
  FormSelectTemplateGroup,
  FormSelectTypeGroup,
} from '@/views/components/forms'
import router from '@/router'

export default {
  components: {
    FormSelectTemplateGroup,
    FormSelectTypeGroup,
    BButton,
    BCol,
    BRow,
    FormSelectGroup,
  },
  props: {
    documentId: {
      type: String,
      require: true,
    },
    customerType: {
      type: String,
      require: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
    },
  },
  setup(props) {
    const { documentId } = toRefs(props)

    const depend = ref({
      masterId: documentId.value,
      typeId: '',
      templateId: '',
    })

    const onCreateDependent = () => {
      router.push({
        name: 'document_create',
        query: {
          masterId: documentId.value,
          typeId: depend.value.typeId,
          templateId: depend.value.templateId,
        },
      })
    }

    watch(
      () => depend.value.typeId,
      () => {
        depend.value.templateId = ''
      }
    )

    return {
      depend,
      onCreateDependent,
    }
  },
}
</script>
