var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('feather-icon',{class:{
      'text-success': _vm.payment === 'paid',
      'text-warning': _vm.payment === 'partPaid',
      'text-danger': _vm.payment !== 'paid' && _vm.payment !== 'partPaid',
      'cursor-pointer': _vm.document,
    },attrs:{"icon":("" + (_vm.payment === 'paid'
        ? 'CheckIcon'
        : _vm.payment === 'partPaid'
        ? 'CheckIcon'
        : 'XIcon')),"size":"21","id":("payment-cell-" + _vm.randomId + "-" + (!!_vm.document ? _vm.document._id : ''))}}),(_vm.document)?_c('b-popover',{ref:"popover",attrs:{"boundary":"window","target":("payment-cell-" + _vm.randomId + "-" + (!!_vm.document ? _vm.document._id : '')),"triggers":"click blur","placement":"topright","custom-class":"payment-cell-popover"}},[_c('div',{staticClass:"d-flex",style:({ gap: '8px' })},[_c('feather-icon',{staticClass:"text-success cursor-pointer",attrs:{"icon":"CheckIcon","size":"21"},on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.onChange('paid'); }).apply(null, arguments)}}}),_c('feather-icon',{staticClass:"text-warning cursor-pointer",attrs:{"icon":"CheckIcon","size":"21"},on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.onChange('partPaid'); }).apply(null, arguments)}}}),_c('feather-icon',{staticClass:"text-danger cursor-pointer",attrs:{"icon":"XIcon","size":"21"},on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.onChange('notPaid'); }).apply(null, arguments)}}})],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }