import { computed, nextTick, watch } from '@vue/composition-api'
import { useCustomer } from '@/models/useCustomer'
import { useTemplate } from '@/models/useTemplate'

const useCustomers = ({ document, editor }) => {
  const customerId = computed(() => document.customerId)
  const { customer, customerRefetch } = useCustomer({ customerId })

  const templateId = computed(() => document.templateId)
  const { template } = useTemplate({ templateId })

  const customerFields = computed(() => {
    const fieldKeys =
      new Set(template.value?.customerFields?.map(f => f.key)) ?? []
    return (
      customer.value.fields
        ?.filter(f => fieldKeys.has(f.key))
        .map(f => ({
          ...f,
          ids:
            template.value?.customerFields
              ?.filter(_f => _f.key === f.key)
              ?.map(_f => _f.id) || [],
        })) || []
    )
  })

  const changeCustomerHandler = async () => {
    await nextTick()
    if (customerFields.value.length) {
      document.customerFields = customerFields.value.map(field => {
        const _field = {
          value: field.value,
          ids: field.ids,
        }
        editor.value?.$children[0]?.$_instance?.fire('updateField', _field)
        return _field
      })
    }
  }

  watch(
    () => document.customerId,
    async () => {
      if (document.customerId) {
        await customerRefetch.value()
      }
      changeCustomerHandler()
    }
  )

  return {
    customer,
    customerFields,
  }
}

export default useCustomers
