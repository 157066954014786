import { computed, watch } from '@vue/composition-api'
import { useMaster } from '@/models/useMaster'
import { useTemplate } from '@/models/useTemplate'
import { useType } from '@/models/useType'

const useMasters = ({ document, editor }) => {
  const masterId = computed(() => document.masterId)
  const { master } = useMaster({ masterId })

  const templateId = computed(() => document.templateId)
  const { template } = useTemplate({ templateId })

  const typeId = computed(() => master.value.typeId)
  const { type } = useType({ typeId })

  const masterFields = computed(() => {
    if (!master.value || !type.value || !template.value) {
      return []
    }
    return (
      type.value.fields?.map(f => ({
        ...f,
        value:
          master.value.typeFields.find(_f => _f.key === f.key)?.value || '',
        ids:
          template.value.masterFields
            ?.filter(_f => _f.key === f.key)
            ?.map(_f => _f.id) || [],
        type: 'masterField',
      })) || []
    )
  })

  const updateMasterFields = () => {
    document.masterFields = masterFields.value.map(f => {
      editor.value?.$children[0]?.$_instance?.fire('updateField', {
        value: f.value,
        ids: f.ids,
      })
      return {
        value: f.value,
        key: f.key,
        name: f.name,
      }
    })
  }

  watch(
    () => document.masterId,
    async () => {
      if (document.masterId) {
        if (!!master.value.customerId) {
          document.customerId = master.value.customerId
        }
      }
    }
  )

  watch(
    () => masterFields.value,
    async () => {
      if (masterFields.value.length > 0) {
        updateMasterFields()
      }
    }
  )

  return {
    master,
    masterFields,
    updateMasterFields,
  }
}

export default useMasters
