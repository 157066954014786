var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('feather-icon',{class:{
      'text-success': _vm.status === 'closed' || _vm.status === 'completed',
      'text-warning': _vm.status === 'inWork' || _vm.status === 'notCompleted',
      'text-danger':
        _vm.status !== 'inWork' &&
        _vm.status !== 'notCompleted' &&
        _vm.status !== 'closed' &&
        _vm.status !== 'completed',
      'cursor-pointer': _vm.document,
    },attrs:{"icon":("" + (_vm.status === 'closed' || _vm.status === 'completed'
        ? 'CheckIcon'
        : _vm.status === 'inWork' || _vm.status === 'notCompleted'
        ? 'ClockIcon'
        : 'XIcon')),"size":"21","id":("status-cell-" + _vm.randomId + "-" + (!!_vm.document ? _vm.document._id : ''))}}),(_vm.document)?_c('b-popover',{ref:"popover",attrs:{"boundary":"window","target":("status-cell-" + _vm.randomId + "-" + (!!_vm.document ? _vm.document._id : '')),"triggers":"click blur","placement":"topright","custom-class":"status-cell-popover"}},[_c('div',{staticClass:"d-flex",style:({ gap: '8px' })},[_c('feather-icon',{staticClass:"text-success cursor-pointer",attrs:{"icon":"CheckIcon","size":"21"},on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.onChange('closed'); }).apply(null, arguments)}}}),_c('feather-icon',{staticClass:"text-warning cursor-pointer",attrs:{"icon":"ClockIcon","size":"21"},on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.onChange('inWork'); }).apply(null, arguments)}}}),_c('feather-icon',{staticClass:"text-danger cursor-pointer",attrs:{"icon":"XIcon","size":"21"},on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.onChange('noСontract'); }).apply(null, arguments)}}})],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }