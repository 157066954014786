<template>
  <div>
    <feather-icon
      :class="{
        'text-success': payment === 'paid',
        'text-warning': payment === 'partPaid',
        'text-danger': payment !== 'paid' && payment !== 'partPaid',
        'cursor-pointer': document,
      }"
      :icon="`${
        payment === 'paid'
          ? 'CheckIcon'
          : payment === 'partPaid'
          ? 'CheckIcon'
          : 'XIcon'
      }`"
      size="21"
      :id="`payment-cell-${randomId}-${!!document ? document._id : ''}`"
    />

    <b-popover
      v-if="document"
      ref="popover"
      boundary="window"
      :target="`payment-cell-${randomId}-${!!document ? document._id : ''}`"
      triggers="click blur"
      placement="topright"
      custom-class="payment-cell-popover"
    >
      <div class="d-flex" :style="{ gap: '8px' }">
        <feather-icon
          class="text-success cursor-pointer"
          icon="CheckIcon"
          size="21"
          @click.stop="() => onChange('paid')"
        />
        <feather-icon
          class="text-warning cursor-pointer"
          icon="CheckIcon"
          size="21"
          @click.stop="() => onChange('partPaid')"
        />
        <feather-icon
          class="text-danger cursor-pointer"
          icon="XIcon"
          size="21"
          @click.stop="() => onChange('notPaid')"
        />
      </div>
    </b-popover>
  </div>
</template>

<script>
import { BPopover, BButton } from 'bootstrap-vue'
import { useDocumentsQuery } from '@/store/documents'
import { computed, toRefs } from '@vue/composition-api'
import useToast from '@/views/components/useToast'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { getRandomId } from '@/utils/helpers'

export default {
  components: {
    BPopover,
    BButton,
  },
  props: {
    payment: {
      type: String,
      default: null,
    },
    document: {
      type: Object,
      default: null,
    },
  },
  setup(props, { refs }) {
    const { t } = useI18nUtils()

    const randomId = getRandomId()
    const toast = useToast()
    const toastsText = {
      edit: t('notifications.success.documents.edit'),
    }
    const documentId = computed(() => document.value?._id || '')
    let { document } = toRefs(props)

    const { mutate: onUpdate } = useDocumentsQuery.update({
      documentId,
      document,
      options: {
        onSuccess(data) {
          if (data.success) {
            toast.success(toastsText.edit)
            document.value = Object.assign(document.value, data.document)
          } else {
            toast.error(JSON.stringify(data.error))
          }
        },
      },
    })

    const onChange = payment => {
      document.value = Object.assign(document.value, { payment })
      refs.popover.$emit('close')
      onUpdate()
    }

    return {
      randomId,
      onChange,
    }
  },
}
</script>

<style lang="scss">
.payment-cell-popover {
  .popover-body {
    min-width: auto;
  }
}
</style>
