import { computed, nextTick, watch } from '@vue/composition-api'
import { useTemplate } from '@/models/useTemplate'
import { useMaster } from '@/models/useMaster'
import { useCustomersQuery } from '@/store/customers'

const useAdditionalCustomers = ({ document, editor }) => {
  const templateId = computed(() => document.templateId)
  const { template } = useTemplate({ templateId })

  const masterId = computed(() => document.masterId)
  const { master } = useMaster({ masterId })

  const customersIds = computed(() =>
    Object.values(document.additionalCustomers)
      .map(({ customerId }) => customerId ?? null)
      .filter(value => !!value)
  )
  const { data: customersData, refetch } = useCustomersQuery.list({
    filters: computed(() => ({ _id: { $in: customersIds.value } })),
    options: {
      enabled: customersIds.value > 0,
    },
  })

  const customers = computed(() => customersData?.value?.customers ?? [])

  const additionalCustomers = computed(() => {
    const entries = Object.entries(template.value?.additionalCustomers ?? {})

    const _customers = entries.map(
      ([customerKey, { fields, type, customerName }]) => {
        if (!document.additionalCustomers[customerKey]) {
          document.additionalCustomers[customerKey] = {
            customerKey,
            fields,
            type,
          }
        }

        const customerId =
          document.additionalCustomers[customerKey]?.customerId ?? ''

        const customer = getCustomer(customerId)
        const customerOptions = getCustomerOptions({ type })

        const fieldKeys = new Set(fields.map(f => f.key))
        const _fields =
          customer?.fields
            ?.filter(f => fieldKeys.has(f.key))
            .map(f => ({
              ...f,
              type: 'additionalCustomersField',
              ids:
                fields?.filter(_f => _f.key === f.key)?.map(_f => _f.id) || [],
            })) || []

        return [
          customerKey,
          {
            customerKey,
            customerName,
            type,
            customerId,
            customerOptions,
            fields: _fields,
          },
        ]
      }
    )
    return Object.fromEntries(_customers)
  })

  function getCustomer(id) {
    return customers.value.find(c => c._id === id) || {}
  }

  function getCustomerOptions({ type }) {
    return (
      customers.value
        .filter(c => (type ? c.customerType === type : true))
        .map(c => ({
          value: c._id,
          label: c.name,
        })) || []
    )
  }

  const updateAdditionalCustomerFields = async (key, value) => {
    document.additionalCustomers[key].customerId = value
    await nextTick()
    const entries = Object.entries(additionalCustomers.value ?? {})
    const _customers = entries?.map(
      ([customerKey, { fields, type, customerId, customerName }]) => [
        customerKey,
        {
          type,
          customerKey,
          customerName,
          customerId: customerKey === key ? value : customerId,
          fields: fields.map(field => ({
            value: field.value,
            ids: field.ids ?? [],
          })),
        },
      ]
    )

    document = Object.assign(document, {
      additionalCustomers: Object.fromEntries(_customers),
    })
  }

  watch(
    () => master.value._id,
    () => {
      if (!!master.value._id) {
        const masterCustomers = Object.values(
          master.value?.additionalCustomers ?? {}
        )

        for (const [customerKey, values] of Object.entries(
          additionalCustomers.value ?? {}
        )) {
          const newCustomerId =
            masterCustomers.find(
              field =>
                field.customerName === values.customerName &&
                field.type === values.type
            )?.customerId ??
            values.customerId ??
            ''

          updateAdditionalCustomerFields(customerKey, newCustomerId)
        }
      }
    }
  )

  watch(
    () => document.additionalCustomers,
    async () => {
      await refetch.value()
      for (const values of Object.values(additionalCustomers.value ?? {})) {
        for (const field of values.fields ?? []) {
          editor.value?.$children[0]?.$_instance?.fire('updateField', {
            value: field.value,
            ids: field.ids ?? [],
          })
        }
      }
    }
  )

  return {
    additionalCustomers,
    updateAdditionalCustomerFields,
  }
}

export default useAdditionalCustomers
