<template>
  <b-table
    :items="items"
    :fields="fields"
    :empty-text="$t('tables.messages.empty')"
    show-empty
    responsive
    no-local-sorting
    striped
    bordered
    small
    tbody-class="font-small-2"
    @sort-changed="value => onSorting && onSorting(value)"
  >
    <template v-if="!!name" #head(name)="data">
      <div>{{ name }}</div>
    </template>
    <template #cell(documentScan)="data">
      <FileCell :file="data.item.documentScan" />
    </template>
    <template #cell(documentAction)="data">
      <feather-icon
        v-if="data.item.documentScan"
        icon="DownloadIcon"
        size="16"
        class="text-primary cursor-pointer"
        @click="
          () => download({ filename: data.item.documentScan, type: 'document' })
        "
      />
    </template>
    <template #cell(status)="data">
      <StatusCell :status="data.item.status" :document="data.item" />
    </template>
    <template #cell(payment)="data">
      <PaymentCell :payment="data.item.payment" :document="data.item" />
    </template>
    <template #cell(name)="data">
      <router-link
        :to="{
          name: 'document_edit',
          params: { documentId: data.item._id },
        }"
        class="text-dark"
      >
        {{ data.item.name }}
      </router-link>
    </template>
  </b-table>
</template>

<script>
import { BLink, BTable } from 'bootstrap-vue'
import StatusCell from '@/views/components/table/StatusCell'
import PaymentCell from '@/views/components/table/PaymentCell'
import FileCell from '@/views/components/table/FileCell'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { getAdditionalValue, getTypeValue } from '@/utils/helpers'
import moment from 'moment/moment'
import { download } from '@/api/attachments'

export default {
  components: {
    BLink,
    BTable,
    StatusCell,
    PaymentCell,
    FileCell,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    withStatus: {
      type: Boolean,
      default: true,
    },
    onSorting: {
      type: Function,
    },
  },
  setup(props) {
    const { t } = useI18nUtils()

    const fields = []

    fields.push({
      key: 'name',
      label: t('tables.headers.name'),
    })

    if (props.type === 'agreement') {
      fields.push(
        {
          key: 'typeFields.agreementNumber',
          label: t('tables.headers.number'),
          sortable: true,
          formatter: (value, key, item) =>
            getAdditionalValue('agreementNumber', item),
        },
        {
          key: 'sum',
          label: t('tables.headers.sum'),
          formatter: (value, key, item) => getTypeValue(key, item),
        },
        {
          key: 'address',
          label: t('tables.headers.address'),
          formatter: (value, key, item) => getTypeValue(key, item),
        },
        {
          key: 'workType',
          label: t('tables.headers.workType'),
          formatter: (value, key, item) => getTypeValue(key, item),
        }
      )
    }

    if (props.type === 'consent') {
      fields.push({
        key: 'documentScan',
        label: t('tables.headers.documentScan'),
        class: 'text-center',
        sortable: true,
      })
    }

    if (props.type === 'check') {
      fields.push(
        {
          key: 'sum',
          label: t('tables.headers.sum'),
          formatter: (value, key, item) => getTypeValue('sum', item),
        },
        {
          key: 'checkDate',
          label: t('tables.headers.date'),
          visible: true,
          formatter: (value, key, item) => {
            const date = getTypeValue(key, item)
            return !!date ? moment(date).format('DD.MM.YYYY') : ''
          },
        },
        {
          key: 'payment',
          label: t('tables.headers.payment'),
          class: ' text-center w-70px',
        }
      )
    }

    if (props.withStatus) {
      fields.push({
        key: 'status',
        label: t('tables.headers.status'),
        class: ' text-center w-70px',
      })
    }

    fields.push({
      key: 'documentAction',
      label: '',
      class: 'w-42px text-center',
    })

    return {
      fields,
      download,
    }
  },
}
</script>
