import { computed, watch } from '@vue/composition-api'
import { useDocumentsQuery } from '@/store/documents'

const useDependents = ({ customerId, documentId }) => {
  const { data: documentsData, refetch: refetchDocuments } =
    useDocumentsQuery.list({
      filters: computed(() => ({
        customerId: customerId?.value,
      })),
      options: {
        enabled: !!customerId?.value,
      },
    })

  const documents = computed(() => documentsData.value?.documents || [])

  const groupingDocuments = computed(() => {
    return documents.value?.reduce(
      (acc, cur) => {
        acc[cur.documentType ? cur.documentType : 'other'].push(cur)
        return acc
      },
      {
        agreement: [],
        check: [],
        receipt: [],
        consent: [],
        act: [],
        extract: [],
        other: [],
      }
    )
  })

  const { data: dependentsData, refetch: refetchDependents } =
    useDocumentsQuery.dependents({
      documentId,
      options: {
        enabled: !!documentId?.value,
      },
    })

  const dependents = computed(() => dependentsData.value?.documents || [])

  const groupingDependents = computed(() => {
    return dependents.value?.reduce(
      (acc, cur) => {
        acc[cur.documentType ? cur.documentType : 'other'].push(cur)
        return acc
      },
      { check: [], receipt: [], consent: [], act: [], extract: [], other: [] }
    )
  })

  watch(
    () => customerId?.value,
    () => {
      refetchDocuments.value()
    }
  )

  watch(
    () => documentId?.value,
    () => {
      refetchDependents.value()
    }
  )

  return {
    dependents,
    groupingDependents,
    groupingDocuments,
  }
}

export default useDependents
