<template>
  <feather-icon
    :class="!!file ? 'text-success' : 'text-danger'"
    :icon="`${!!file ? 'CheckIcon' : 'XIcon'}`"
    size="21"
  />
</template>

<script>
export default {
  props: {
    file: {
      type: String,
      default: null,
    },
  },
}
</script>
