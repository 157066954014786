import { computed, nextTick, unref, watch } from '@vue/composition-api'
import { useTemplate } from '@/models/useTemplate'

const useTemplates = ({ document }) => {
  const templateId = computed(() => document.templateId)
  const { template } = useTemplate({ templateId })

  const templateFields = computed(
    () =>
      template.value.fields?.map(f => ({
        ...f,
        value:
          document.templateFields.find(_f => _f.id === f.id)?.value || null,
      })) || []
  )

  const updateTemplateField = async field => {
    await nextTick()
    document.templateFields = document.templateFields.map(f =>
      f.id !== field.id ? f : { id: field.id, value: field.value }
    )
  }

  watch(
    () => document.templateId,
    async () => {
      if (!document.templateId) {
        document.customerId = ''
        document.masterId = ''
      }
    }
  )

  watch(
    () => template.value,
    async () => {
      document.templateFields =
        template.value.fields?.map(f => ({
          id: f.id,
          value:
            document.templateFields.find(_f => _f.id === f.id)?.value || null,
        })) || []

      if (document.customerType !== template.value.customerType) {
        document.customerType = template.value.customerType
      }
    }
  )

  return {
    template,
    templateFields,
    updateTemplateField,
  }
}

export default useTemplates
